import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import Tags from '../components/tags'
import * as styles from './blog-post.module.css'

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulBlogPost')
    const plainTextDescription = documentToPlainTextString(
      JSON.parse(post.description.raw)
    )

    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { gatsbyImage, title } = node.data.target
        return (
          <GatsbyImage
              image={getImage(gatsbyImage)}
              alt={title}
          />
          )
        },
      },
    };

    const vimeoLink = post.vimeo;
    let vimeoVideo;

    if(vimeoLink) {
      vimeoVideo = <div className={styles.vimeoWrapper}><iframe className={styles.vimeo} title="vimeo-player" src={`https://player.vimeo.com/video/${vimeoLink}`} width="100%" height="100%" frameBorder="0" allowFullScreen></iframe></div>
    }

    return (
      <Layout location={this.props.location}>
        <Seo
          title={post.title}
          description={plainTextDescription}
          image={`${post.heroImage.resize.src}`}
        />
        <Hero
          image={post.heroImage?.gatsbyImage}
          title={post.title}
          content={post.description}
        />
        <Tags tags={post.tags} />

        <div className={styles.container}>
          <div className={styles.article}>
            <div className={styles.body}>
              {post.body?.raw && renderRichText(post.body, options)}
              {vimeoVideo}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
  ) {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      heroImage {
        gatsbyImage(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 630, width: 1200) {
          src
        }
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImage(width: 1000)
            __typename
          }
        }
      }
      tags
      description {
        raw
      }
      vimeo
    }
  }
`
